import React, { useState, useEffect } from "react";
import { easeIn, easeInOut, motion } from "framer-motion";
import { IoCaretBackCircle } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const InternalChat = () => {
  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "Hello! How can I help you today?",
      sender: "support",
      timestamp: new Date().toLocaleTimeString(),
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const navigate = useNavigate();
  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsTyping(true);

    // Remove typing indicator after 1 second of inactivity
    setTimeout(() => {
      setIsTyping(false);
    }, 1000);
  };

  // Handle sending a message
  const handleSendMessage = () => {
    if (inputValue.trim()) {
      const newMessage = {
        id: messages.length + 1,
        text: inputValue,
        sender: "user", // differentiates between user and support
        timestamp: new Date().toLocaleTimeString(),
      };
      setMessages([...messages, newMessage]);
      setInputValue("");
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <motion.div
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ stiffness: "easeInOut", duration: 0.5 }}
      className="flex flex-col h-full  bg-gray-100"
    >
      {/* Chat Header */}
      <div className="flex items-center justify-between p-4 bg-blue-600 text-white rounded-t-lg shadow-md">
        <div
          onClick={handleGoBack}
          className="cursor-pointer flex gap-2 items-center "
        >
          <IoCaretBackCircle
            data-tooltip-id="goBack"
            data-tooltip-content="Go Back"
            size={18} // You can adjust the icon size here
          />
          <h1 className="text-lg font-semibold">Chat Support</h1>
        </div>
        <Tooltip id="goBack" />

        <div className="text-sm">Online</div>
      </div>

      {/* Chat Messages Container */}
      <div className="flex-1 p-4 space-y-4 overflow-y-auto bg-white border border-gray-200 rounded-b-lg shadow-md">
        {messages.map((message) => (
          <motion.div
            key={message.id}
            className={`flex ${
              message.sender === "user" ? "justify-end" : "justify-start"
            }`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div
              className={`max-w-xs p-3 rounded-lg ${
                message.sender === "user"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
            >
              <p>{message.text}</p>
              <div className="text-xs text-gray-500 text-right mt-1">
                {message.timestamp}
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      {/* Chat Input */}
      <div className="flex items-center p-4 bg-white border-t border-gray-200">
        <input
          type="text"
          className="flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type your message..."
        />
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={handleSendMessage}
          className="p-2 ml-2 text-white bg-blue-600 rounded-md shadow-lg"
        >
          Send
        </motion.button>
      </div>
    </motion.div>
  );
};

export default InternalChat;
