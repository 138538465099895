import React, { useEffect, useState, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BsChatFill } from "react-icons/bs";
import AvailableState from "./AvailableState";
import { MdHistory } from "react-icons/md";
import { motion } from "framer-motion";
import { BsChatSquareDotsFill } from "react-icons/bs";
import internalChat from "../assets/consultation.gif";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { Link } from "react-router-dom";

import ChatHistory from "./agent/chatWindow/HistoryTab";
import { FaWhatsapp } from "react-icons/fa";
import "./loader.css";
function ConversationList({
  conversations,
  onJoinThread,
  interactionId,
  wrapUpthreadId,
  onWrapUpSubmit,
  online,
  onJoinAni,
  currentHistory,
  setCurrentHistory,
  onViewHistory,
  getCollapseState,
  unreadMessages,
  sendMessageToParent,
  socket,
  rejectConversation,
  accessToken,
  wrappedUpConversations,
  setViewHistory,
}) {
  const [displayedConversations, setDisplayedConversations] = useState([]);

  const [queuedConversations, setQueuedConversations] = useState([]);
  const [acceptedThreads, setAcceptedThreads] = useState([]);
  const [status, setStatus] = useState("online");
  const [prevConversations, setPrevConversations] = useState({});
  const [deletedConversations, setDeletedConversations] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false); // Collapsible state
  const audioPlayerRef = useRef(null);
  const [activeThreadId, setActiveThreadId] = useState(null);
  const [activeAni, setActiveAni] = useState(null);

  const [historyConversations, setHistoryConversations] = useState([]);
  const [acceptLoading, setAcceptLoading] = useState(false);

  const fetchHistory = async () => {
    try {
      const response = await axios.get(
        "http://localhost:5500/conversations-history"
      ); // Replace with your API endpoint
      setHistoryConversations(response.data);
    } catch (error) {
      console.error("Error fetching history:", error);
    }
  };

  const openHistoryModal = () => {
    setShowHistory((prev) => !prev);
    setViewHistory((prev) => !prev);
  };

  const closeHistoryModal = () => {
    setShowHistory(false);
  };

  // // wrap up conversation...
  // const handleWrapUpConversation = (interactionId) => {
  //   setDisplayedConversations((prevDisplayedConversations) =>
  //     prevDisplayedConversations.filter(
  //       (conversation) => conversation.interactionId !== interactionId
  //     )
  //   );

  //   setWrappedUpConversations((prevWrappedUpConversations) => [
  //     ...prevWrappedUpConversations,
  //     ...displayedConversations.filter(
  //       (conversation) => conversation.interactionId === interactionId
  //     ),
  //   ]);
  // } // fetchHistory();;

  // useEffect(() => {
  //   if (wrapUpthreadId) {
  //     handleWrapUpConversation(wrapUpthreadId);
  //   }
  // }, [wrapUpthreadId]);

  // Listen for socket events and update the conversation list accordingly
  useEffect(() => {
    const handleConversationAccepted = ({ threadId, agentId }) => {
      console.log(`Conversation ${threadId} was accepted by agent ${agentId}`);
      if (agentId !== socket.id) {
        setPrevConversations((prevConversations) => {
          const updatedConversations = { ...prevConversations };
          delete updatedConversations[threadId];
          return updatedConversations;
        });
      }
    };

    // Listen for the conversationAccepted event
    socket.on("conversationAccepted", handleConversationAccepted);

    return () => {
      socket.off("conversationAccepted", handleConversationAccepted);
    };
  }, [socket]);

  // Update conversations based on changes in conversations and deletedConversations
  useEffect(() => {
    const updatedConversations = { ...prevConversations };

    // Remove conversations that no longer exist
    Object.keys(updatedConversations).forEach((threadId) => {
      if (!conversations[threadId]) {
        delete updatedConversations[threadId];
      }
    });

    // Add new conversations
    Object.keys(conversations).forEach((threadId) => {
      if (
        !updatedConversations[threadId] &&
        !deletedConversations.includes(threadId)
      ) {
        updatedConversations[threadId] = conversations[threadId];
      }
    });

    setPrevConversations(updatedConversations);

    // Play audio alert for new conversations
    const newThreadIds = Object.keys(conversations).filter(
      (threadId) => !prevConversations[threadId]
    );
    if (newThreadIds.length > 0 && audioPlayerRef.current) {
      audioPlayerRef.current.play();
    }

    updateDisplayedConversations(updatedConversations);
  }, [conversations, deletedConversations]);

  // Handle wrapping up a thread
  // useEffect(() => {
  //   if (wrapUpthreadId) {
  //     setDisplayedConversations((prevDisplayedConversations) => {
  //       const updatedDisplayedConversations = prevDisplayedConversations.filter(
  //         (conversation) => conversation.convId !== wrapUpthreadId
  //       );

  //       if (queuedConversations.length > 0) {
  //         const nextConversation = queuedConversations.shift();
  //         updatedDisplayedConversations.push(nextConversation);
  //         setQueuedConversations([...queuedConversations]);
  //       }

  //       return updatedDisplayedConversations;
  //     });
  //   }
  // }, [wrapUpthreadId]);

  // const updateDisplayedConversations = (updatedConversations) => {
  //   const conversationArray = Object.values(updatedConversations);

  //   if (conversationArray.length > 3) {
  //     setDisplayedConversations(conversationArray.slice(0, 3));
  //     setQueuedConversations(conversationArray.slice(3));
  //   } else {
  //     setDisplayedConversations(conversationArray);
  //     setQueuedConversations([]);
  //   }
  // };
  const updateDisplayedConversations = (updatedConversations) => {
    const uniqueConversations = [];
    const uniqueIds = new Set(); // Track displayed conversation IDs to ensure uniqueness

    // Loop through updatedConversations and only add unique conversationIds
    for (const conversation of Object.values(updatedConversations)) {
      if (!uniqueIds.has(conversation.interactionId)) {
        uniqueConversations.push(conversation);
        uniqueIds.add(conversation.interactionId); // Mark conversationId as displayed
        console.log("unique ids", uniqueIds);
      }
    }

    // Display only up to 3 unique conversations, queue the rest
    if (uniqueConversations.length > 3) {
      setDisplayedConversations(uniqueConversations.slice(0, 3));
      setQueuedConversations(uniqueConversations.slice(3));
    } else {
      setDisplayedConversations(uniqueConversations);
      setQueuedConversations([]);
    }
  };

  useEffect(() => {
    getCollapseState(isCollapsed);
  }, [isCollapsed]);

  const getIconAndStyle = (conversation) => {
    // console.log("ICON KA CONVERSATION", conversation);
    if (conversation.type === "chatEvent") {
      return {
        icon: <BsChatFill />,
        style: "liveChat",
      };
    } else if (conversation?.type === "whatsappEvent") {
      return {
        icon: <FaWhatsapp />,

        style: "whatsappChat",
      };
    }
  };

  const switchChat = (data) => {
    const { ani, convId, interactionId, agentId } = data;
    setActiveThreadId(interactionId);
    setActiveAni(ani);
    if (ani) {
      onJoinThread(convId, interactionId, ani, agentId);
    } else {
      onJoinThread(convId, interactionId, ani, agentId);
    }

    if (!acceptedThreads.includes(interactionId)) {
      setAcceptedThreads((prev) => [...prev, interactionId]);
    }
  };

  // const handleAcceptClick = (convId, interactionId, agentId) => {
  //   const message = {
  //     event: "Accept",
  //     convId: convId,
  //     interactionId: interactionId,
  //   };
  //   const data = {
  //     convId: convId,
  //     interactionId: interactionId,
  //     agentId: agentId,
  //   };
  //   console.log("ACCEPTED ", interactionId);
  //   sendMessageToParent(message);
  //   switchChat(convId, agentId, interactionId);
  //   socket.emit("joinConversation", data);
  // };

  const handleAcceptClick = async (data) => {
    const { convId, interactionId, ani, intId } = data;
    const agentId = localStorage.getItem("agentId");
    console.log("ACCEPT agent id ", agentId);
    console.log("ACCEPTED ANI", ani);
    try {
      // Send POST request to your API using Axios
      const taskId = ani ? intId : interactionId;

      setAcceptLoading(true);
      const response = await axios.post(
        "https://api.wxcc-us1.cisco.com/v1/tasks/" + taskId + "/accept",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Conversation accepted successfully:", response.data);

      // Send the message to the parent window (if necessary in your app)
      const message = {
        event: "Accept",
        convId: convId,
        interactionId: interactionId,
      };
      const chatData = {
        convId: convId,
        interactionId: interactionId,
        agentId,
      };
      const whatsappData = {
        convId: convId,
        interactionId: interactionId,
        ani: ani,
        agentId,
      };
      setAcceptLoading(false);
      console.log("ACCEPTED ", interactionId);
      sendMessageToParent(message);
      if (ani) {
        switchChat(whatsappData);
        socket.emit("joinWhatsapp", whatsappData);
      } else {
        switchChat(chatData);
        socket.emit("joinConversation", chatData);
      }

      sendMessageToParent(message);

      // Switch chat and join the conversation with socket
      // switchChat(convId, interactionId, agentId);
      // socket.emit("joinConversation", convId);
    } catch (error) {
      // Handle error
      console.error("Error sending conversation ID to API:", error);
    }
  };

  const handleDeleteClick = (interactionId) => {
    // Remove from displayed conversations
    setDisplayedConversations((prevDisplayedConversations) =>
      prevDisplayedConversations.filter(
        (conversation) => conversation.interactionId !== interactionId
      )
    );

    // Remove from queued conversations
    setQueuedConversations((prevQueuedConversations) =>
      prevQueuedConversations.filter(
        (conversation) => conversation.interactionId !== interactionId
      )
    );

    // Remove from previous conversations
    setPrevConversations((prevPrevConversations) => {
      const updatedConversations = { ...prevPrevConversations };
      delete updatedConversations[interactionId];
      return updatedConversations;
    });

    // Track the deleted conversation
    setDeletedConversations((prevDeletedConversations) => [
      ...prevDeletedConversations,
      interactionId,
    ]);
  };

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  const handleViewHistory = () => {
    setShowHistory((prev) => !prev);
  };
  // export current conversation to chat app

  return (
    <motion.div
      animate={{ width: isCollapsed ? "4rem" : "100%" }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      className={`relative border-r border-slate-300 h-full`}
    >
      <div className="flex items-center gap-2 bg-blue-200 p-2">
        <button
          onClick={() => setIsCollapsed((prev) => !prev)}
          className="text-blue-100 text-lg xs:text-sm rounded-full p-3 bg-blue-500 flex items-center justify-center"
        >
          <BsChatFill />
        </button>
        {!isCollapsed && (
          <h2 className="text-xl xs:text-sm p-2 text-center" id="conv">
            Conversations
          </h2>
        )}
        {!isCollapsed && (
          <button
            onClick={openHistoryModal}
            className="bg-blue-500 text-white p-1 rounded-full"
          >
            {showHistory ? <MdHistory /> : <MdHistory />}
          </button>
          // <button
          //   className="ml-auto bg-gray-200 text-gray-700 px-2 py-1 rounded hover:bg-gray-300"
          //   onClick={handleViewHistory}
          // >
          //   {showHistory ? <MdHistory /> : <MdHistory />}
          // </button>
        )}
      </div>

      <div className="p-2">
        <ul>
          {showHistory
            ? wrappedUpConversations.map((conversation) => (
                <li
                  key={conversation.convId}
                  onClick={() => {
                    setCurrentHistory(conversation);

                    setViewHistory(true);
                  }}
                  className={`flex flex-col md:flex-row  items-start cursor-pointer px-2 py-1 mt-1 rounded-md justify-center md:items-center md:justify-between border bg-gray-100 border-gray-400`}
                >
                  <div className="flex gap-2 items-center">
                    <span className="p-0 md:p-1 rounded-full text-sm md:text-lg text-gray-500">
                      {<BsChatFill />}
                    </span>
                    {!isCollapsed && (
                      <span className="font-bold text-xs md:text-sm flex-col flex">
                        {conversation.customerName}
                        <p className="font-light text-xs md:text-sm">
                          {conversation.wrapUpReason}
                        </p>
                      </span>
                    )}
                  </div>
                </li>
              ))
            : displayedConversations
                .filter(
                  (conversation) =>
                    status === "online" ||
                    acceptedThreads.includes(conversation.convId)
                )
                .map((conversation) => {
                  const { icon, style } = getIconAndStyle(conversation);
                  // const icon = "";
                  // const style = "liveChat";
                  const isAccepted = acceptedThreads.includes(
                    conversation?.interactionId
                  );

                  return (
                    <li
                      key={conversation.convId}
                      onClick={() => {
                        console.log("CONVERSATION CLICKED", conversation);
                        const data = {
                          convId: conversation?.convId,

                          agentId: conversation?.agentId,
                          interactionId: conversation.interactionId,
                          ani: conversation?.ANI,
                        };
                        switchChat(data);
                      }}
                      className={`${
                        activeThreadId === conversation?.interactionId
                          ? "bg-green-200"
                          : "bg-gray-100"
                      } ${
                        isCollapsed
                          ? "flex group justify-center items-center w-12 h-12 relative rounded-md  "
                          : `relative group flex justify-between gap-1 items-start cursor-pointer p-1 mt-1 rounded-lg border bg-gray-100 border-gray-400`
                      }`}
                    >
                      {unreadMessages[conversation.interactionId] > 0 && (
                        <span className="bg-red-500 absolute -right-1 -top-2 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                          {unreadMessages[conversation.interactionId]}
                        </span>
                      )}
                      <div
                        className={`${
                          isCollapsed
                            ? "flex items-center justify-center gap- rounded-md2"
                            : "flex items-center gap-2 "
                        }`}
                      >
                        <span
                          className={`${
                            isCollapsed
                              ? `${
                                  activeThreadId === conversation.interactionId
                                    ? "bg-green-500"
                                    : style === "whatsappChat"
                                    ? "bg-green-400" // WhatsApp-specific background color when collapsed
                                    : "bg-gray-500"
                                } w-[35px] h-[35px] rounded-full flex justify-center items-center`
                              : "p-1 rounded-full text-xs text-white"
                          } ${
                            style === "liveChat"
                              ? "text-blue-100 bg-blue-500 text-lg"
                              : style === "whatsappChat"
                              ? "text-green-100 bg-green-400 text-lg" // WhatsApp-specific color styling when expanded
                              : "text-gray-500 bg-gray-300"
                          }`}
                        >
                          {isCollapsed
                            ? conversation.customerName.charAt(0)
                            : icon}
                        </span>

                        {!isCollapsed && (
                          <span className="font-bold relative text-xs md:text-sm flex-col flex">
                            {conversation.customerName}
                            {style === "liveChat" && (
                              <p className="font-light text-xs md:text-sm">
                                {conversation.queueName || "Chat_Q"}
                              </p>
                            )}
                            {style === "whatsappChat" && (
                              <p className="font-light text-xs md:text-sm">
                                {conversation.queueName || "WhatsApp_Q"}
                              </p>
                            )}
                          </span>
                        )}
                      </div>

                      {/* Hover details in collapsed state */}
                      {isCollapsed && (
                        <motion.div
                          initial={{ scale: 0, opacity: 0 }}
                          animate={{ scale: 1, opacity: 1 }}
                          transition={{ duration: 0.3 }}
                          className="absolute left-14 top-0 bg-white border border-gray-300 shadow-lg p-4 rounded-lg hidden group-hover:block z-50 hover:block"
                        >
                          <div className="flex gap-6 justify-between">
                            <div>
                              <span className="font-bold text-sm">
                                {conversation.customerName}
                              </span>
                              <p className="font-light text-xs">
                                {conversation.queueName || "Chat_Q"}
                              </p>
                            </div>
                          </div>
                        </motion.div>
                      )}

                      <div className="flex items-center space-x-2">
                        {!isAccepted && !isCollapsed && (
                          <button
                            className="rounded-full px-2 py-1 bg-green-400 text-white font-semibold flex justify-center items-center text-sm ml-3 hover:bg-green-600"
                            onClick={(e) => {
                              e.stopPropagation();

                              if (conversation?.ANI) {
                                // If ani is present, pass ani and interactionId to handleAcceptClick
                                const data = {
                                  convId: conversation?.convId,
                                  interactionId: conversation?.interactionId,
                                  ani: conversation?.ANI,
                                  intId: conversation?.intId,
                                };
                                handleAcceptClick(data);
                              } else {
                                // If ani is not present, pass the original parameters
                                const data = {
                                  convId: conversation?.convId,
                                  interactionId: conversation?.interactionId,
                                  agentId: conversation?.agentId,
                                };
                                handleAcceptClick(data);
                              }
                            }}
                          >
                            {acceptLoading ? (
                              <span class="acceptLoader"></span>
                            ) : (
                              <BsChatSquareDotsFill />
                            )}
                          </button>
                        )}
                        {!isCollapsed && !isAccepted && (
                          <button
                            className="rounded-full px-2 py-1 bg-red-400 text-white font-bold text-sm ml-3 hover:bg-red-600"
                            onClick={(e) => {
                              e.stopPropagation();
                              rejectConversation(
                                conversation?.convId,
                                conversation?.interactionId
                              );
                            }}
                          >
                            <AiOutlineDelete />
                          </button>
                        )}
                      </div>
                    </li>
                  );
                })}
        </ul>
      </div>

      <div
        className={` ${
          isCollapsed
            ? " flex-col-reverse gap-2 items-center hidden"
            : " flex-row gap-2 hidden "
        } fixed bottom-0  h-16 w-full shadow-md bg-gray-100 `}
      >
        {/* Online/Offline Status */}

        <div
          className={` p-2  ${
            isCollapsed ? "rounded-full text-white text-xs" : ""
          }`}
        >
          <AvailableState
            isCollapsed={isCollapsed}
            onStatusChange={handleStatusChange}
            online={online}
          />
        </div>
        {/* internal chat button */}
        <div className=" w-12 h-12 rounded-full cursor-pointer">
          <Link to="/internalChat">
            <img
              data-tooltip-id="internal-chat"
              data-tooltip-content="Internal Chat"
              src={internalChat}
              alt="internal chat"
              className="rounded-full"
            />
          </Link>
          <Tooltip id="internal-chat" />
        </div>
      </div>
    </motion.div>
  );
}

export default ConversationList;
