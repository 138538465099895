// TemplateModal.js
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlinePlus,
  AiOutlineClose,
} from "react-icons/ai";

// Framer Motion Variants for smooth animations
const modalVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
};

const TemplateModal = ({ isOpen, onClose, templates, setTemplates }) => {
  const [newTemplate, setNewTemplate] = useState("");
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingTemplate, setEditingTemplate] = useState("");

  const handleAddTemplate = () => {
    if (newTemplate.trim()) {
      setTemplates([...templates, newTemplate]);
      setNewTemplate("");
    }
  };

  const handleDeleteTemplate = (index) => {
    const updatedTemplates = templates.filter((_, i) => i !== index);
    setTemplates(updatedTemplates);
  };

  const handleSaveEdit = () => {
    if (editingTemplate.trim()) {
      const updatedTemplates = [...templates];
      updatedTemplates[editingIndex] = editingTemplate;
      setTemplates(updatedTemplates);
      setEditingIndex(null);
      setEditingTemplate("");
    }
  };

  return (
    <>
      {isOpen && (
        <motion.div
          className="fixed   inset-0 bg-black bg-opacity-50 flex items-center justify-center"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          transition={{ duration: 0.3 }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96 relative">
            <button className="absolute top-2 right-2" onClick={onClose}>
              <AiOutlineClose size={20} />
            </button>

            <div className="space-y-3">
              <div
                className="h-40 overflow-y-auto "
                style={{ scrollbarWidth: "thin" }}
              >
                {templates.map((template, index) => (
                  <div
                    key={index}
                    className="flex justify-between border-b mt-2 items-center"
                  >
                    {editingIndex === index ? (
                      <input
                        value={editingTemplate}
                        onChange={(e) => setEditingTemplate(e.target.value)}
                        className="border border-gray-300 rounded p-1 w-full"
                      />
                    ) : (
                      <span>{template}</span>
                    )}
                    <div className="flex items-center ml-2 space-x-2">
                      {editingIndex === index ? (
                        <button
                          className="text-blue-500"
                          onClick={handleSaveEdit}
                        >
                          Save
                        </button>
                      ) : (
                        <AiOutlineEdit
                          className="cursor-pointer text-gray-500 hover:text-blue-500"
                          onClick={() => {
                            setEditingIndex(index);
                            setEditingTemplate(template);
                          }}
                        />
                      )}
                      <AiOutlineDelete
                        className="cursor-pointer text-red-500 hover:text-red-700"
                        onClick={() => handleDeleteTemplate(index)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/* Input to add a new template */}
              <div className="flex items-center">
                <input
                  value={newTemplate}
                  onChange={(e) => setNewTemplate(e.target.value)}
                  placeholder="Add new template"
                  className="border border-gray-300 rounded p-1 w-full"
                />
                <button
                  className="ml-2 text-green-500"
                  onClick={handleAddTemplate}
                >
                  <AiOutlinePlus />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default TemplateModal;
