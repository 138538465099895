import io from "socket.io-client";

const queryParams = `?ngrok-skip-browser-warning=true&user-agent=CustomUserAgentValue`;
// Connect to socket.io server
const socket = io(`https://services.uniagent.consiliumapps.com/`, {
  transportOptions: {
    polling: {
      extraHeaders: {
        "ngrok-skip-browser-warning": "true",
        "User-Agent": "CustomUserAgentValue",
      },
    },
  },
});

export default socket;
