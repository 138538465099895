import React, { createContext, useState } from "react";

export const SupervisorContext = createContext();

export const SupervisorProvider = ({ children }) => {
  const [agents, setAgents] = useState([]); // list of all agents
  const [queues, setQueues] = useState([]); // list of queues
  const [currentQueue, setCurrentQueue] = useState(null); // selected queue
  const [currentChat, setCurrentChat] = useState(null); // selected chat
  const [agentStatusFilter, setAgentStatusFilter] = useState("All"); // status filter

  // Other relevant states (e.g., chat data, reports)

  return (
    <SupervisorContext.Provider
      value={{
        agents,
        setAgents,
        queues,
        setQueues,
        currentQueue,
        setCurrentQueue,
        currentChat,
        setCurrentChat,
        agentStatusFilter,
        setAgentStatusFilter,
        // Add more values and methods as needed
      }}
    >
      {children}
    </SupervisorContext.Provider>
  );
};
