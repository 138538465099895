import React from "react";
import { motion } from "framer-motion";

const ChatHistory = ({ conversationData }) => {
  return (
    <div className="p-4 bg-gray-100 min-h-screen flex flex-col">
      {/* Conversation Header */}
      <div className="text-center py-4 border-b border-gray-300">
        <h1 className="text-lg font-semibold text-gray-700">
          Conversation with {conversationData.customerName}
        </h1>
        <p className="text-sm text-gray-500">
          {conversationData.customerEmail}
        </p>
      </div>

      {/* Message History */}
      <div className="flex-1 overflow-y-auto py-4 space-y-3">
        {conversationData.messages.map((msg, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2, delay: index * 0.1 }}
            className={`flex ${
              msg.sender === "customer" ? "justify-start" : "justify-end"
            }`}
          >
            <div
              className={`max-w-xs px-4 py-2 rounded-lg ${
                msg.sender === "customer"
                  ? "bg-blue-100 text-blue-800"
                  : "bg-green-100 text-green-800"
              } shadow-md`}
            >
              <p className="text-sm">{msg.message}</p>
              <span className="block text-xs text-gray-500 mt-1">
                {new Date(msg.timestamp).toLocaleString()}
              </span>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ChatHistory;
