import React, { useState, useEffect, useRef, useCallback } from "react";
import ProgressBar from "@ramonak/react-progress-bar";

import { IoSend } from "react-icons/io5";
import { motion } from "framer-motion";
import { AiFillEdit, AiOutlineFileText } from "react-icons/ai";
import { wrapUp } from "../../CTI";
import axios from "axios";
import "../../chatApp.css";
import { AiOutlinePaperClip } from "react-icons/ai";
import { formatDuration } from "../../../utils/FormatDuration";
import ConversationHistory from "./HistoryTab";
// Import your sound file
import chatSound from "../../../assets/incommingChat.mp3";
import CustomerDetails from "../../chatControl";
import EmojiPicker from "emoji-picker-react";
import { BsEmojiSmile } from "react-icons/bs";
import TemplateModal from "./TemplateModal";
import { IoMdCloseCircle } from "react-icons/io";
import Avatar from "./Avatar";
import SpellCheck from "./SpellCheck";
import { debounce } from "../../../utils/debounce";
import useTypingHandler from "../../../hooks/userTyping";
import { showNotification } from "../../../utils/showNotification,";
import ChatHistory from "./HistoryTab";
function ChatWindow({
  messages = [],
  onSendMessage,
  customerName,
  threadId,
  queue,
  email,
  intId,
  sendWaMessage,
  onWrapUpSubmit,
  sendMessageToParent,
  onConversationEnd,
  accessToken,
  interactionId,
  socket,
  convId,

  currentHistory,
  getWrappedUpData,
  wrappedUpConversations,
  conversations,
  viewHistorty,
  setViewHistory,
}) {
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState(messages);
  const [endedConversations, setEndedConversations] = useState([]);
  const [durations, setDurations] = useState({});
  const [timers, setTimers] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});
  const [showWrapUp, setShowWrapUp] = useState(false);
  const [showTemplates, setShowTemplates] = useState(false); // State to control template visibility
  const soundRef = useRef(null); // Reference for the sound,
  const [attachment, setAttachment] = useState(null);
  const messagesEndRef = useRef(null); // Reference for auto-scrolling
  const [showEmoji, setShowEmoji] = useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [newTemplate, setNewTemplate] = useState(""); // New state to add a new template
  const [editingIndex, setEditingIndex] = useState(null);
  const chatEndRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wrapUpThreadId, setWrapUpThreadId] = useState(null);
  // const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = React.useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const { handleTyping, isTyping } = useTypingHandler();
  const [text, setText] = useState("");
  const [endLoading, setEndLoading] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const spellCheckRef = useRef(); // Ref for accessing SpellCheck functions
  // States for progress, upload status, and errors
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [uploadResult, setUploadResult] = useState("");
  const [auxCode, setAuxCode] = useState("");
  const [templates, setTemplates] = useState([
    "Hello, how can I assist you today?",
    "Can you please provide more details?",
    "Please verify your emailID to proceed.",
    "A password reset link has been sent to your email.",
    "I'll look into that for you.",
    "Thank you for your patience.",
    "Is there anything else I can help with?",
  ]);

  // Debounced version of the getSpellCheck function
  // const debouncedSpellCheck = useCallback(debounce(getSpellCheck, 300), []);
  const debouncedSpellCheck = useCallback(
    debounce((word) => {
      // console.log(spellCheckRef.current, spellCheckRef);
      if (spellCheckRef.current) {
        // console.log("word is", word);
        spellCheckRef.current.checkSpelling(word); // Call the exposed checkSpelling function
      }
    }, 300),
    []
  );

  // Handle input change and trigger spell check
  const handleInputChange = (e) => {
    const newText = e.target.value;

    // First, handle typing (from your custom hook)
    handleTyping(e); // No need to store return value, we're already getting it from e.target.value

    // Update the message with the latest value
    setMessage(newText);

    // Get the cursor position
    const cursorPos = e.target.selectionStart;
    setCursorPosition(cursorPos);

    // Now perform spell check logic
    const words = newText.slice(0, cursorPos).split(" "); // Ensure newText is being used
    const lastWord = words[words.length - 1];

    if (lastWord) {
      debouncedSpellCheck(lastWord); // Perform debounced spell check on the last word typed
    } else {
      setSuggestions([]); // Clear suggestions if no word to check
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   setShowEmoji(false);
  //   setShowTemplates(false);
  //   if (file) {
  //     setAttachment(file);
  //   }
  // };

  // handle file change..
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const totalChunks = 1;
    const chunkNumber = 0;
    const chunkFileName = file.name;
    // Hide emoji and templates
    setShowEmoji(false);
    setShowTemplates(false);

    // If a file is selected
    if (file) {
      // Show a progress bar
      setProgress(0);
      setUploading(true);

      // Create form data
      const formData = new FormData();
      formData.append("file", file);
      formData.append("chunkNumber", chunkNumber);
      formData.append("totalChunks", totalChunks);
      formData.append("fileName", chunkFileName);

      // Call the API using fetch
      fetch(
        `https://wcapi-us-site-1.imiengage.io/api/conversations/${convId}/uploadfile`,
        {
          method: "POST",
          headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            authorization: localStorage.getItem("accessToken"),
          },
          body: formData,
          // Track progress
          onUploadProgress: (progressEvent) => {
            console.log("progress ", progressEvent.loaded);
            if (progressEvent.total) {
              const percent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percent);
            }
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          // Handle success response
          console.log(data);
          if (data.status === true) {
            // File is valid, set the attachment
            setAttachment(file);
            setUploadResult(data.result);
            setUploading(false);
          } else {
            console.log("from else", data);
            // Show error message if file is not valid
            setUploading(false);
            setError("Invalid file. Please select a valid file.");
          }
        })
        .catch((error) => {
          setUploading(false);
          setError("An error occurred during the upload. Please try again.");
        });
    } else {
      setError("No file selected. Please select a file.");
    }
  };

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const handleEmoji = () => {
    setShowTemplates(false);

    setShowEmoji((prev) => !prev);
  };

  // Effect to add the keyboard shortcut for toggling templates
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if both Ctrl key and T key are pressed
      if (event.altKey && event.key.toLowerCase() === "t") {
        event.preventDefault();
        setShowTemplates((prev) => !prev);
      }
    };

    // Attach event listener to the whole document
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown); // Clean up on unmount
    };
  }, []); // Empty dependency array ensures this runs only once
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  // Effect to update chat messages, play notification sound, and show notification
  useEffect(() => {
    // Check if the new message is from the customer (incoming message)
    if (
      messages.length > chatMessages.length &&
      messages[messages.length - 1].sender === "customer"
    ) {
      // Play the sound and show notification only if the document is not visible

      if (document.visibilityState === "hidden") {
        soundRef.current.play(); // Play the notification sound
        showNotification(messages[messages.length - 1].message); // Show browser notification
      }
      soundRef.current.play();
      setChatMessages(messages);
      scrollToBottom();
    } else {
      setChatMessages(messages);
      scrollToBottom();
    }
  }, [messages, chatMessages.length]);
  // Function to auto-scroll to the bottom of the chat

  useEffect(() => {
    // Request notification permission on component mount
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);
  // Function to auto-scroll to the bottom of the chat

  useEffect(() => {
    // Start a timer for the conversation if it hasn't ended and doesn't already have a timer
    if (!endedConversations.includes(threadId) && !timers[threadId]) {
      const startTime = Date.now() - (durations[threadId]?.elapsed || 0) * 1000;

      // Set up an interval to update the elapsed time every second
      const interval = setInterval(() => {
        setDurations((prevDurations) => ({
          ...prevDurations,
          [threadId]: {
            elapsed: Math.floor((Date.now() - startTime) / 1000),
            startTime,
          },
        }));
      }, 1000);

      // Store the interval ID for this threadId in the timers object
      setTimers((prevTimers) => ({ ...prevTimers, [threadId]: interval }));
    }

    // Clean up: only clear the timer for the specific threadId that is ended
    return () => {
      if (timers[threadId] && endedConversations.includes(threadId)) {
        clearInterval(timers[threadId]);

        // Remove the timer for the specific threadId from the timers state
        setTimers((prevTimers) => {
          const { [threadId]: _, ...remainingTimers } = prevTimers;
          return remainingTimers;
        });
      }
    };
  }, [threadId, endedConversations, timers, durations]);

  const handleSend = async () => {
    setSuggestions([]);
    if (
      message.trim() === "" &&
      !endedConversations.includes(threadId) &&
      !attachment
    )
      return;

    let fileURL = null;

    if (attachment) {
      const attach = [uploadResult];
      const payload = {
        agentid: localStorage.getItem("agentId"),
        ci: "1862242897232725",
        fi: "",
        convId: convId,
        channelid: "3",
        tmplid: "",
        attch: attach,
        is_first_msg_sent: false,
        senderid: "",
        email_to: "userId",
        email_cc: "",
        email_reply_type: "",
        msg: message,
        msg_markup: "",
        msg_json: "",
        actiontmplid: 0,
        ispublic: 0,
      };
      // ****************************
      try {
        const response = await axios.post(
          `https://wcapi-us-site-1.imiengage.io/api/conversations/${convId}/messages`,
          payload,
          {
            headers: {
              accept: "*/*",
              "accept-language": "en-US,en;q=0.9",
              authorization: localStorage.getItem("accessToken"),
            },
          }
        );
        console.log(
          "second api for attachment:",
          response.data.result?.history[0]?.attachmentlist[0]?.path
        );
        fileURL = response.data.result?.history[0]?.attachmentlist[0]?.path;
      } catch (error) {
        console.error(
          "Error sending message to external API:",
          error.response ? error.response.data : error.message
        );
        throw error;
      }
    }

    // Send the message along with the file details (if any)
    onSendMessage(
      message,
      attachment
        ? { name: attachment.name, url: fileURL, type: attachment.type }
        : null
    );

    // console.log(
    //   "Message sent with attachment:",
    //   attachment ? attachment.name : "No attachment"
    // );

    // Reset message and attachment after sending
    setMessage("");
    setAttachment(null);
    setShowEmoji(false);
  };

  // *********** end Conversation *****************************

  const handleEndConversation = async () => {
    const endMessage = {
      userId: "agent",
      message: "Conversation ended by the agent.",
      timestamp: new Date(),
    };
    setWrapUpThreadId(threadId);
    setChatMessages((prevMessages) => [...prevMessages, endMessage]);
    setEndedConversations((prev) => [...prev, threadId]);
    clearInterval(timers[threadId]);
    setShowWrapUp(true);

    try {
      // Send POST request to your API using Axios
      setEndLoading(true);
      const taskId = intId ? intId : interactionId;
      const response = await axios.post(
        "https://api.wxcc-us1.cisco.com/v1/tasks/" + taskId + "/end",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Make sure accessToken is correctly set
            "Content-Type": "application/json",
          },
        }
      );
      setEndLoading(false);

      const message = {
        event: "End conversation",
        interactionId: interactionId,
      };
      const endData = {
        agentId: localStorage.getItem("agentId"),
        interactionId: interactionId,
        convId: convId,
      };
      // console.log("Ended ", interactionId);
      sendMessageToParent(message);
      socket.emit("endConversation", endData);
    } catch (error) {
      // Handle error
      // console.error("Error sending conversation ID to API:", error);
    }
    const data = {
      interactionId: interactionId,
      agentId: "",
    };
    setDisabledButtons((prev) => ({ ...prev, [threadId]: true }));
    socket.emit("endConversation", data);
  };

  // ****************** wrap up conversations ******************************

  const [selectedWrapUp, setSelectedOption] = useState("");

  const handleSelectChange = (event) => {
    const selectedOption = event.target.selectedOptions[0];

    console.log(
      "selected wrapup",
      event.target.selectedOptions[0],
      event.target.selectedOptions
    );
    const selectedValue = selectedOption.value;
    const selectedText = selectedOption.text;
    setSelectedOption(selectedText);
    setAuxCode(selectedValue);
  };

  const handleSubmit = async () => {
    const message = {
      event: "wrap UP",
      convId: threadId,
    };

    sendMessageToParent(message);
    if (selectedWrapUp) {
      onWrapUpSubmit(threadId, selectedWrapUp, interactionId);
      // console.log("WRAPPED UP CONVERSATIONS", wrappedUpConversations);
      // console.log("WRAPUP KA THREAD ID ", threadId);
      setShowWrapUp(false);

      const body = { auxCodeId: auxCode, wrapUpReason: selectedWrapUp };
      const taskId = intId ? intId : interactionId;
      try {
        const response = await axios.post(
          "https://api.wxcc-us1.cisco.com/v1/tasks/" + taskId + "/wrapup",
          body,
          {
            // config object
            headers: {
              authorization: `Bearer ${accessToken}`,
              accept: "application/json, text/plain, */*",
              "accept-language": "en-US,en;q=0.9",
              "content-type": "application/json",
            },
          }
        );
      } catch (e) {
        alert("Wrap-up failed: " + e.message); // Optional: Log the error message
      }
    }
  };

  const handleTemplateClick = (template) => {
    setMessage(template);
    setShowTemplates(false);
  };

  //emoji picker
  const onEmojiClick = (emojiObject, event) => {
    setChosenEmoji(emojiObject);
    setMessage((prevInput) => prevInput + emojiObject.emoji);
  };
  // Helper function to determine if we should display the sender info

  const shouldShowSenderInfo = (index) => {
    if (index === 0) return true; // Always show info for the first message

    return chatMessages[index].userId !== chatMessages[index - 1].userId;
  };

  return (
    <>
      {viewHistorty ? (
        <ChatHistory conversationData={currentHistory} />
      ) : (
        <div className="chat-window  flex flex-col w-full relative">
          {/* Audio element for notification sound */}

          <audio ref={soundRef} src={chatSound} preload="auto" />

          <CustomerDetails
            customerName={customerName}
            handleSubmit={handleSubmit}
            selectedWrapUp={selectedWrapUp}
            handleEndConversation={handleEndConversation}
            queue={queue}
            handleSelectChange={handleSelectChange}
            showWrapUp={showWrapUp}
            disabledButtons={disabledButtons}
            threadId={threadId}
            endedConversations={endedConversations}
            email={email}
            endLoading={endLoading}
            durations={durations}
            formatDuration={formatDuration}
            wrapUpThreadId={wrapUpThreadId}
            onWrapUpSubmit={onWrapUpSubmit}
            accessToken={accessToken}
          />

          <div
            className=" z-0  relative flex flex-col max-h-[calc(100vh-9rem)] p-4  w-full  overflow-y-auto"
            style={{ scrollbarWidth: "none" }}
          >
            {chatMessages.map((msg, index) => (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                key={index}
                className="mb-2"
              >
                {/* Show sender info only when userId changes */}
                {shouldShowSenderInfo(index) && (
                  <div className="text-gray-500 flex gap-1 items-baseline m-1 text-xs mb-1">
                    {/* {console.log(msg)} */}
                    <Avatar
                      name={
                        msg.sender === "customer"
                          ? customerName
                          : localStorage.getItem("agentName")
                      }
                    />
                    <span className="font-semibold">
                      {msg.sender === "customer"
                        ? customerName
                        : localStorage.getItem("agentName")}
                    </span>{" "}
                    <span>{new Date(msg.timestamp).toLocaleTimeString()}</span>
                  </div>
                )}

                <div
                  className={`p-3 inline-block min-w-20 ml-9 relative rounded-md shadow-md ${
                    msg.sender !== "customer"
                      ? "self-end bg-blue-100"
                      : "self-start bg-white"
                  }`}
                >
                  {/* **************************************************************************** */}

                  {/* If there is an attachment, handle image and file preview */}
                  {msg.attachment && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="mt-2"
                    >
                      {/* Check if the attachment object has a type property before accessing it */}
                      {msg.attachment.type &&
                      msg.attachment.type.startsWith("image/") ? (
                        <div className="flex flex-col items-center">
                          <img
                            src={msg.attachment.url}
                            alt={msg.attachment.name}
                            className="w-24 h-24 object-cover rounded-md"
                          />
                          <a
                            href={msg.attachment.url}
                            download={msg.attachment.name}
                            className="text-blue-500 hover:underline mt-2"
                          >
                            Download {msg.attachment.name}
                          </a>
                        </div>
                      ) : (
                        // For non-image files, just show a download button
                        <a
                          href={msg.attachment.url}
                          download={msg.attachment.name}
                          className="text-blue-500 hover:underline"
                        >
                          Download {msg.attachment.name}
                        </a>
                      )}
                    </motion.div>
                  )}
                  {/* Render the message text */}
                  {msg.message && (
                    <p className="text-sm text-gray-700 ">{msg.message}</p>
                  )}
                  {/* ************************************************************************ */}
                  <p className="absolute right-1 bottom-1 -m-[3px] text-[.55rem]">
                    {new Date(msg.timestamp).toLocaleTimeString()}
                  </p>
                </div>
                <div ref={chatEndRef} />
              </motion.div>
            ))}
          </div>
          <TemplateModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            templates={templates}
            setTemplates={setTemplates}
            className="z-10"
          />
          <SpellCheck
            setMessage={setMessage}
            message={message}
            handleInputChange={handleInputChange}
            cursorPosition={cursorPosition}
            suggestions={suggestions}
            ref={spellCheckRef}
            setSuggestions={setSuggestions}
          />

          <div className="message-input  w-full flex-col justify-start  p-2 fixed bottom-1 flex gap-1  bg-white border-t border-gray-300">
            {/* upper */}

            <div className="flex gap-1 relative">
              <button
                onClick={() => {
                  setShowTemplates(!showTemplates);
                  setShowEmoji(false);
                }}
                className="  text-gray-700 p-1 rounded-full   hover:bg-gray-100 transition-all duration-150 focus:outline-none"
              >
                <AiOutlineFileText className="" />
              </button>
              <input
                id="fileInput"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                className="z-10"
              />
              <button
                className="  text-gray-700 p-1 rounded-full   hover:bg-gray-100 focus:outline-none"
                onClick={() => {
                  document.getElementById("fileInput").click();
                  setShowTemplates(false);
                  setShowEmoji(false);
                }}
              >
                <AiOutlinePaperClip className=" " />
              </button>

              <button
                onClick={handleEmoji}
                className="hover:bg-slate-100 rounded-full p-1"
              >
                {" "}
                <BsEmojiSmile />
              </button>
              <div className="absolute bottom-24">
                {showEmoji && <EmojiPicker onEmojiClick={onEmojiClick} />}
              </div>
            </div>
            {/* lower */}
            <div>
              {showTemplates && (
                <div
                  className="absolute bottom-24  bg-white border   border-gray-300 shadow-md rounded-lg  w-64 max-h-60 overflow-y-auto"
                  style={{ scrollbarWidth: "thin" }}
                >
                  <div className="w-full sticky flex justify-end  top-0 p-2 bg-white">
                    <button
                      className="bg-blue-500  text-white px-4 font-bold text-lg   rounded-full flex items-center justify-center h-[40px] w-[40px] "
                      onClick={() => {
                        setIsModalOpen(true);
                        setShowTemplates(false);
                      }}
                    >
                      <AiFillEdit classNamev="text-xl font-bold" />
                    </button>
                  </div>
                  {templates.map((template, index) => (
                    <button
                      key={index}
                      onClick={() => handleTemplateClick(template)}
                      className="block w-full text-left p-2 text-gray-700 hover:bg-gray-200 rounded-md"
                    >
                      {template}
                    </button>
                  ))}
                </div>
              )}

              <div className=" flex relative gap-1 w-full   ">
                {uploading && (
                  <div>
                    <ProgressBar completed={progress} />;
                  </div>
                )}
                {error && !uploading && (
                  <div className="error-message">{error}</div>
                )}
                {attachment && (
                  <div className="attachment-preview w-40 h-5 overflow-hidden absolute left-0 top-[-5px]    bg-gray-200 px-2 gap-1 rounded-full flex text-sm text-gray-800">
                    <span className="w-[80%] overflow-hidden ">
                      {attachment.name}
                    </span>
                    <span className="h-full w-[20%]  flex justify-center items-center">
                      {" "}
                      <IoMdCloseCircle
                        className="text-red-600 cursor-pointer "
                        onClick={() => {
                          setAttachment(null);
                        }}
                      />
                    </span>
                  </div>
                )}

                <input
                  id="messageInput"
                  type="text"
                  disabled={endedConversations.includes(threadId)}
                  value={message}
                  spellCheck="true"
                  onChange={handleInputChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSend();
                    }
                  }}
                  placeholder="Write a message..."
                  className={`  p-2   w-[80%] ${
                    attachment && "mt-2"
                  }  outline-none focus:outline-none`}
                />

                <button
                  id=""
                  onClick={handleSend}
                  disabled={endedConversations.includes(threadId)}
                  className={`bg-slate-300 hover:bg-blue-400 transition-all duration-150   flex gap-1 items-center justify-center  px-4 rounded-full text-slate-500 hover:text-white
             py-1 ${
               endedConversations.includes(threadId) ? "cursor-not-allowed" : ""
             }`}
                >
                  <IoSend className="text-sm" />
                  <span className="text-xs">Send</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatWindow;
