import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./chatApp.css";

const InitialPage = ({ socket, sendMessageToParent, accessToken }) => {
  const [loading, setLoading] = useState(false);
  // const [accessToken, setAccessToken] = useState(null);
  const navigate = useNavigate();

  // Function to fetch IMI token and agent info periodically
  const fetchImiToken = async () => {
    try {
      // Step 1: Make the API request to get user data
      const { data } = await axios.get(
        "https://wcapi-us-site-1.imiengage.io/api/oauth/wxccauthorizenew/",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "content-type": "application/json",
            grant_type: "authorization_code",
          },
        }
      );
      console.log("TOKEN DATA", data);
      const imitoken = data?.result?.accessToken;
      const agentId = data?.result?.agentId;
      const agentName = data?.result?.agentName;
      const agentEmail = data?.result?.agentEmail;

      if (imitoken) {
        localStorage.setItem("accessToken", imitoken);
        localStorage.setItem("agentName", agentName);
        localStorage.setItem("agentId", agentId);
        localStorage.setItem("agentEmail", agentEmail);
        console.log("imi tokent is stored in localstorage");
      } else {
        console.error("Access token is missing from the response.");
        alert("Failed to retrieve access token.");
        return;
      }

      // Step 3: Check if accessToken is present in localStorage
      const storedImiToken = localStorage.getItem("accessToken");

      if (storedImiToken) {
        // Step 4: Navigate to the agent route if the access token is present
        console.log(
          "Access token found in localStorage, navigating to /agent..."
        );
        navigate("/agent");
      } else {
        console.error("Access token is not present in localStorage.");
        alert("No access token found. Cannot proceed.");
      }
    } catch (error) {
      console.error("Error during API call:", error);
      alert("Failed to go live. Please try again.");
    } finally {
      setLoading(false);
    }

    // Schedule the task to run every 29 minutes
    setInterval(fetchImiToken, 29 * 60 * 1000);
  };

  // Main function to handle Go Live logic
  // const handleGoLive = async () => {
  //   setLoading(true);
  //   try {
  //     const { data } = await axios.get("http://localhost:5500/api/getUserData");
  //     console.log(data);
  //     const { accessToken, agentId, loginType } = data.data;
  //     sendMessageToParent(data.data);
  //     // Store the access token in state
  //     // setAccessToken(accessToken);

  //     const intervalId = setInterval(fetchImiTokenAndAgentInfo, 29 * 60 * 1000); // 29 minutes
  //     // Proceed based on the login type
  //     if (loginType === "agent" || loginType === "supervisor") {
  //       // Emit WebSocket event based on role
  //       socket.emit("goLive", { uuid: agentId, role: loginType });

  //       // Navigate to the appropriate page
  //       if (loginType === "agent") {
  //         navigate("/agent");
  //       } else if (loginType === "supervisor") {
  //         navigate("/supervisor");
  //       }

  //       // Start the periodic fetching of IMI token and agent info every 29 minutes

  //       // Clear interval when component unmounts
  //       return () => clearInterval(intervalId);
  //     } else {
  //       alert("Invalid login type received from the server.");
  //     }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //     alert("Failed to go live. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  //   // navigate("/agent");
  // };
  // const handleGoLive = async () => {
  //   setLoading(true);
  //   try {
  //     // Fetch user data
  //     const { data } = await axios.get("http://localhost:5500/api/getUserData");
  //     console.log(data);
  //     const { accessToken, agentId, loginType } = data.data;
  //     sendMessageToParent(data.data);

  //     // Log the data to ensure correct response
  //     console.log(
  //       "AccessToken:",
  //       accessToken,
  //       "AgentID:",
  //       agentId,
  //       "LoginType:",
  //       loginType
  //     );

  //     // Emit WebSocket event based on role
  //     socket.emit("goLive", { uuid: agentId, role: loginType });

  //     // Set up the periodic fetching of IMI token and agent info
  //     const intervalId = setInterval(fetchImiTokenAndAgentInfo, 29 * 60 * 1000); // 29 minutes

  //     // Proceed based on the login type
  //     if (loginType === "agent" || loginType === "supervisor") {
  //       // Wait for fetchImiTokenAndAgentInfo to complete and return status 200
  //       const fetchImiResponse = await fetchImiTokenAndAgentInfo();

  //       if (fetchImiResponse && fetchImiResponse.status === 200) {
  //         // Navigate to the agent page only if status is 200
  //         if (loginType === "agent") {
  //           navigate("/agent");
  //         } else if (loginType === "supervisor") {
  //           navigate("/supervisor");
  //         }
  //       } else {
  //         alert("Failed to fetch IMI token. Please try again.");
  //       }

  //       // Clear interval when component unmounts
  //       return () => clearInterval(intervalId);
  //     } else {
  //       alert("Invalid login type received from the server.");
  //     }
  //   } catch (error) {
  //     console.error("Error during API call:", error);
  //     alert("Failed to go live. Please try again.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // **********************************************
  const handleGoLive = async () => {
    setLoading(true);
    console.log("access token for cce is ", accessToken);

    fetchImiToken();
  };

  return (
    <div className="h-screen w-full flex items-center justify-center bg-gray-800">
      {loading ? (
        <div className="loader"></div>
      ) : (
        <button
          className="text-white font-bold text-2xl py-4 px-8 rounded-full bg-gradient-to-r from-green-400 via-green-500 to-green-600 shadow-lg hover:shadow-green-400/50 transition duration-300 ease-in-out transform hover:scale-105 animate-pulse"
          onClick={handleGoLive}
        >
          Go Live
        </button>
      )}
    </div>
  );
};

export default InitialPage;
