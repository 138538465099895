import React, { useState, useImperativeHandle, forwardRef } from "react";
import axios from "axios";

// Use forwardRef to correctly pass the ref to the component
const SpellCheck = forwardRef(
  (
    { setMessage, message, cursorPosition, suggestions, setSuggestions },
    ref
  ) => {
    const [selectedWord, setSelectedWord] = useState("");

    const YANDEX_SPELLER_API_URL =
      "https://speller.yandex.net/services/spellservice.json/checkText";

    const getSpellCheck = async (text) => {
      try {
        const response = await axios.get(YANDEX_SPELLER_API_URL, {
          params: {
            text: encodeURIComponent(text), // Ensure the text is URL-encoded properly
          },
        });

        if (response.data.length > 0) {
          const suggestions = response.data.map((error) => ({
            word: error.word,
            suggestions: error.s, // These are the suggestions for the misspelled word
          }));

          setSuggestions(suggestions);
          setSelectedWord(text);
        } else {
          setSuggestions([]); // Clear suggestions if no spelling mistakes are found
        }
      } catch (error) {
        console.error(
          "Error fetching spell check suggestions:",
          error.message || error
        );
        alert(
          "There was an error fetching spell check suggestions. Please try again."
        );
      }
    };

    // Expose getSpellCheck to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
      checkSpelling: (text) => getSpellCheck(text), // Expose the function
    }));

    const handleSuggestionClick = (word, suggestion) => {
      const textBeforeCursor = message.slice(0, cursorPosition);
      const textAfterCursor = message.slice(cursorPosition);

      const lastIndex = textBeforeCursor.lastIndexOf(word);

      const newMessage =
        textBeforeCursor.slice(0, lastIndex) +
        suggestion +
        textBeforeCursor.slice(lastIndex + word.length) +
        textAfterCursor;

      setMessage(newMessage);
      setSuggestions([]);
    };

    return (
      <>
        {/* Render spelling suggestions */}
        {suggestions.length > 0 && (
          <div className="bg-gray-50 fixed bottom-24 w-full p-1 border border-gray-300 rounded-lg">
            <ul className="space-y-2">
              {suggestions.map((suggestion, index) => (
                <li key={index}>
                  <ul
                    className="flex overflow-auto"
                    style={{ scrollbarWidth: "none" }}
                  >
                    {suggestion.suggestions.map((sug, sugIndex) => (
                      <li
                        key={sugIndex}
                        className="text-gray-200 border-gray-500 border-l bg-gray-700 px-2 py-1 cursor-pointer hover:bg-gray-600 transition-all duration-150"
                        onClick={() =>
                          handleSuggestionClick(suggestion.word, sug)
                        }
                      >
                        {sug}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  }
);

export default SpellCheck;
