// _____________________________________________________________________________________________________\
import React, { useState, useEffect, useRef } from "react";
import socket from "../webScoket/webSocket";
import ConversationList from "./conversationList";
import { Routes, Route, useLocation } from "react-router-dom";
import InitialPage from "./initialPage";
import { motion } from "framer-motion";
import notificationSound from "../assets/notificationSound.mp3";
import ChatWindow from "../components/agent/chatWindow/ChatWindow";
import InternalChat from "./agent/InternalChat";
import axios from "axios";
import ChatHistory from "./agent/chatWindow/HistoryTab";

// const socket = io("http://localhost:5500");

function ChatApp() {
  const [newConversation, setNewConversation] = useState([]);
  const [whatsappConversations, setWhatsappConversations] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState();
  const [currentAni, setCurrentAni] = useState();
  const location = useLocation();
  const { online } = location.state || { online: false };
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState({});
  const notificationAudio = useRef(null);
  const [accessToken, setAccessToken] = useState("");
  const [replyData, setReplyData] = useState({});
  const [currentConvId, setCurrentConvId] = useState("");
  const [wrappedUpConversations, setWrappedUpConversations] = useState([]);
  const [currentHistory, setCurrentHistory] = useState();
  const [viewHistory, setViewHistory] = useState(false);

  useEffect(() => {
    console.log("CURRENT HISTORY", currentHistory);
  }, [currentHistory, viewHistory]);

  // Helper function to update the collapsed statef
  const getCollapseState = (state) => {
    setIsCollapsed(state);
  };

  // wrapup data
  // const getWrappedUpData = (data) => {
  //   setWrappedUpData(data);
  // };

  // Handle incoming messages from the parent iframe
  useEffect(() => {
    const handleMessage = (event) => {
      // https://gaurav-pc.ucce12.com:44300
      if (event.origin === "https://gaurav-pc.ucce12.com:44300") {
        const receivedData = event.data;
        console.log("received data", receivedData);

        if (event.data.type === "chatEvent") {
          console.log(
            "print the details",
            event.data.conversationId,
            event.data.convId
          );
        }
        console.log("parent sent data ", receivedData);

        if (
          receivedData &&
          (receivedData?.type === "chatEvent" ||
            receivedData?.type === "whatsappEvent")
        ) {
          // Check if the interaction id already exists in the state
          const interactionExists = newConversation.some(
            (interaction) =>
              interaction.intercationId === receivedData?.interactionId
          );

          if (interactionExists) {
            alert(
              `Interaction with ID ${receivedData?.intercationId} already exists.`
            );
          } else {
            // Otherwise, add the new interaction to the state
            setNewConversation((prevConversations) => [
              ...prevConversations,
              { ...receivedData, messages: [] }, // Initialize messages array for each conversation
            ]);
            console.log("NEW CONVERSATION", newConversation);
          }
        }
        if (receivedData && receivedData?.token) {
          console.log("PARENT ACCESS TOKEN", receivedData.token);
          setAccessToken(receivedData?.token);
        }
      }
    };

    // console.log("NEW CONVERSATION", newConversation);

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [newConversation, whatsappConversations]);

  const sendMessageToParent = (messageData) => {
    const message = {
      data: messageData || "Default message from Child",
      timestamp: new Date().toISOString(),
    };

    window.parent.postMessage(message, "https://gaurav-pc.ucce12.com:44300");
    console.log("Message sent to parent:", message);
  };
  // Handle incoming messages for a specific conversation for chat
  useEffect(() => {
    const handleNewMessage = (data) => {
      console.log("HANDLE MESSAGE DATA ", data);

      const { message, userId, convId, timestamp, interactionId, sender, ani } =
        data;
      const newmessage = {
        message: message,
        userId: userId,
        sender: sender,
        convId: convId,
        interactionId,
        timestamp: timestamp,
      };

      // Update the conversation with the new message
      setNewConversation((prevConversations) =>
        prevConversations.map((conversation) =>
          conversation.interactionId === interactionId
            ? {
                ...conversation,
                messages: [...conversation.messages, newmessage],
              }
            : conversation
        )
      );
      if (interactionId !== currentThreadId) {
        console.log("***********", currentThreadId, interactionId);
        notificationAudio.current.play();
        setUnreadMessages((prevUnreadMessages) => ({
          ...prevUnreadMessages,
          [interactionId]: (prevUnreadMessages[interactionId] || 0) + 1, // Increment unread message count
        }));
      }
    };

    // Listen for new messages on the socket
    socket.on("newMessage", handleNewMessage);

    return () => {
      // Clean up the event listener
      socket.off("newMessage", handleNewMessage);
    };
  }, [currentThreadId]);

  // **************************************************************************************************************************
  // Function to handle when an agent joins a conversation thread
  const joinThread = (convId, interactionId, ani, agentId) => {
    console.log("joined thread", interactionId, convId, ani, agentId);
    const agetnId = localStorage.getItem("agentId");
    setCurrentThreadId(interactionId);
    setCurrentAni(ani);
    setCurrentConvId(convId);

    // Notify the server that the agent joined this threa
    setUnreadMessages((prevUnreadMessages) => ({
      ...prevUnreadMessages,
      [interactionId]: 0, // Reset unread messages for this conversation
    }));
  };

  // ********** join ANI ******************************'

  // Send message to api
  const sendMessageToApi = async (conversationId, userId, messageContent) => {
    const agentId = localStorage.getItem("agentId");
    console.log("conversationID for reply", conversationId);
    const payload = {
      agentid: agentId,
      ci: "1862242897232725",
      fi: "",
      convId: conversationId,
      channelid: "3",
      tmplid: "",
      attch: [""],
      is_first_msg_sent: false,
      senderid: "",
      email_to: userId,
      email_cc: "",
      email_reply_type: "",
      msg: messageContent,
      msg_markup: "",
      msg_json: "",
      actiontmplid: 0,
      ispublic: 0,
    };
    const imiToken = localStorage.getItem("accessToken");
    console.log("IMI TOKEN ", imiToken);
    try {
      const response = await axios.post(
        `https://wcapi-us-site-1.imiengage.io/api/conversations/${conversationId}/messages`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${imiToken}`,
          },
        }
      );
      console.log("Message sent to external API:", response.data);
      return response.data;
    } catch (error) {
      console.error(
        "Error sending message to external API:",
        error.response ? error.response.data : error.message
      );
      throw error;
    }
  };

  // Function to send a message in the current thread
  const sendMessage = (messageContent, attachment) => {
    const threadId = currentThreadId;
    const ANI = currentAni;
    const timestamp = new Date().toISOString();
    const userId = "a"; // Example agent
    const convId = currentConvId;
    const agentId = localStorage.getItem("agentId");
    const message = {
      message: messageContent,
      timestamp,
      userId,
      ANI,
      convId,
      interactionId: threadId,
      attachment,
    };
    console.log("AGENT REPLY MESSAGE", message);
    // Emit the sendMessage event to the server

    // Update the conversation locally
    setNewConversation((prevConversations) =>
      prevConversations.map((conversation) =>
        conversation.interactionId === threadId
          ? { ...conversation, messages: [...conversation.messages, message] }
          : conversation
      )
    );
    const conversationId = message.convId;

    sendMessageToApi(conversationId, userId, messageContent);
    socket.emit("reply", message);
  };

  // ************************** send whatsapp message *******************************
  const sendWaMessage = async (message, destination) => {
    const data = {
      appid: "a_171685819922932030",
      deliverychannel: "whatsapp",
      channels: {
        "OTT-Messaging": {
          wa: {
            type: "text",
            preview_url: true,
            text: {
              body: message,
            },
          },
        },
      },
      destination: [
        {
          waid: [destination],
        },
      ],
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.us.webexconnect.io/resources/v1/messaging",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        key: "4d060b06-2177-11ef-ac2a-02b7e040ae47",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      console.log("Message sent successfully:", response.data);
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response?.data || error.message
      );
    }
  };

  // ************** reject conversation *****************

  const rejectConversation = async (convId, interactionId) => {
    try {
      // Send a POST request to the API with the conversation ID

      const response = await axios.post(
        "https://api.wxcc-us1.cisco.com/v1/tasks/" + interactionId + "/reject",
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Conversation rejected ", convId, response.data);

      // Update the state only after the API call is successful
      setNewConversation((prevConversations) =>
        prevConversations.filter(
          (conversation) => conversation.interactionId !== interactionId
        )
      );

      if (interactionId === currentThreadId) {
        setCurrentThreadId(null);
        setCurrentAni(null);
      }
      const rejectData = {
        convId: currentConvId,
        interactionId,
        agentId: localStorage.getItem("agentId"),
      };
      socket.emit("reject", rejectData);
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error rejecting conversation: ", error);
    }
  };
  const handleWrapUp = (interactionId, wrapUpReason) => {
    console.log(
      `Wrap-up reason: ${wrapUpReason} for conversation ID: ${interactionId}`
    );

    // Remove the conversation with the specific interactionId from the newConversation list
    setNewConversation((prevConversations) =>
      prevConversations.filter(
        (conversation) => conversation.interactionId !== interactionId
      )
    );
    // Find the wrapped-up conversation in the existing newConversation list
    const wrappedUpConversation = newConversation.find(
      (conversation) => conversation.interactionId === interactionId
    );

    // Add the wrapped-up conversation to wrappedUpConversations if it exists
    if (wrappedUpConversation) {
      setWrappedUpConversations((prevWrappedUp) => {
        // Check if the conversation already exists in wrappedUpConversations
        const isAlreadyWrapped = prevWrappedUp.some(
          (conversation) =>
            conversation.interactionId === wrappedUpConversation.interactionId
        );

        // Only add to wrappedUpConversations if it doesn't already exist
        if (!isAlreadyWrapped) {
          return [...prevWrappedUp, { ...wrappedUpConversation, wrapUpReason }];
        }

        // If it already exists, return the current state without modification
        return prevWrappedUp;
      });
    }

    if (interactionId === currentThreadId) {
      setCurrentThreadId(null);
    }
  };

  return (
    <>
      <audio ref={notificationAudio} src={notificationSound} preload="auto" />
      <Routes>
        <Route
          path="/agent"
          element={
            <div className="chat-app w-[100vw] flex">
              <motion.div
                className={`${isCollapsed ? "w-16" : "w-[35%]"} z-10`}
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                style={{ scrollbarWidth: "thin" }}
              >
                <ConversationList
                  setCurrentHistory={setCurrentHistory}
                  setViewHistory={setViewHistory}
                  viewHistory={viewHistory}
                  conversations={newConversation} // Pass the array of conversations
                  wrappedUpConversations={wrappedUpConversations}
                  onJoinThread={joinThread}
                  online={online}
                  getCollapseState={getCollapseState}
                  socket={socket}
                  unreadMessages={unreadMessages}
                  sendMessageToParent={sendMessageToParent}
                  rejectConversation={rejectConversation}
                  accessToken={accessToken}
                  interactionId={
                    newConversation.find(
                      (conv) => conv.interactionId === currentThreadId
                    )?.interactionId
                  }
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
                className={`${
                  isCollapsed ? "w-[calc(100%-4rem)]" : "w-[75%]"
                } z-0`}
              >
                {currentHistory && viewHistory && (
                  <ChatHistory conversationData={currentHistory} />
                )}
                {currentThreadId && !viewHistory ? (
                  <ChatWindow
                    wrappedUpConversations={wrappedUpConversations}
                    currentHistory={currentHistory}
                    viewHistory={viewHistory}
                    convId={currentConvId}
                    messages={
                      newConversation.find(
                        (conv) => conv.interactionId === currentThreadId
                      )?.messages || []
                    }
                    customerName={
                      newConversation.find(
                        (conv) => conv.interactionId === currentThreadId
                      )?.customerName
                    }
                    email={
                      newConversation.find(
                        (conv) => conv.interactionId === currentThreadId
                      )?.emailId
                    }
                    queue={
                      newConversation.find(
                        (conv) => conv.interactionId === currentThreadId
                      )?.queue
                    }
                    interactionId={
                      newConversation.find(
                        (conv) => conv.interactionId === currentThreadId
                      )?.interactionId
                    }
                    intId={
                      newConversation.find(
                        (conv) => conv.interactionId === currentThreadId
                      )?.intId
                    }
                    onSendMessage={sendMessage} // Pass sendMessage function to ChatWindow
                    sendWaMessage={sendWaMessage}
                    conversations={newConversation}
                    onWrapUpSubmit={handleWrapUp}
                    threadId={currentThreadId}
                    sendMessageToParent={sendMessageToParent}
                    accessToken={accessToken}
                    socket={socket}
                  />
                ) : (
                  <div className="flex flex-col w-full h-full justify-center items-center">
                    <p className="font-bold text-lg text-slate-500">
                      No conversation open!
                    </p>
                    <p className="text-xs font-light text-slate-600">
                      Click on a conversation to open chat.
                    </p>
                  </div>
                )}
              </motion.div>
            </div>
          }
        />
        <Route
          path="/"
          element={
            <InitialPage
              socket={socket}
              sendMessageToParent={sendMessageToParent}
              accessToken={accessToken}
            />
          }
        />
        <Route path="/internalChat" element={<InternalChat />}></Route>
      </Routes>
    </>
  );
}

export default ChatApp;
