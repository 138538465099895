import React, { useState, useEffect, useRef } from "react";
import {
  AiOutlineCheck,
  AiOutlineMinus,
  AiOutlineDown,
  AiOutlineUp,
} from "react-icons/ai";

const AvailableState = ({ onStatusChange, isCollapsed }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("online"); // Marked Online by default
  const [onlineTime, setOnlineTime] = useState(0); // Time in seconds
  const [offlineTime, setOfflineTime] = useState(0); // Time in seconds

  const onlineInterval = useRef(null);
  const offlineInterval = useRef(null);

  const options = [
    { value: "online", label: "Online", color: "bg-green-400" },
    { value: "offline", label: "Offline", color: "bg-red-400" },
  ];

  const handleOptionClick = (option) => {
    setStatus(option);
    setIsOpen(false);
    onStatusChange(option); // Pass the status change to the parent component
  };

  // Start the appropriate timer
  useEffect(() => {
    if (status === "online") {
      clearInterval(offlineInterval.current);
      setOfflineTime(0); // Reset offline timer
      onlineInterval.current = setInterval(() => {
        setOnlineTime((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(onlineInterval.current);
      setOnlineTime(0); // Reset online timer
      offlineInterval.current = setInterval(() => {
        setOfflineTime((prev) => prev + 1);
      }, 1000);
    }

    // Clean up on unmount
    return () => {
      clearInterval(onlineInterval.current);
      clearInterval(offlineInterval.current);
    };
  }, [status]);

  // Function to change status from the console or external messages
  const stateChange = (newState) => {
    const state = newState ? "online" : "offline";
    setStatus(state);
    onStatusChange(state); // Notify parent component
  };

  // Expose the function to the global scope for dev console usage and listen to messages from the parent window
  useEffect(() => {
    window.stateChange = stateChange;

    const messageListener = (event) => {
      if (event.data && event.data.type === "statusChange") {
        stateChange(event.data.status === "online");
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      delete window.stateChange; // Clean up when the component is unmounted
      window.removeEventListener("message", messageListener);
    };
  }, []);

  // Format time as HH:MM:SS
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs > 0 ? `${hrs}:` : ""}${mins < 10 ? `0${mins}` : mins}:${
      secs < 10 ? `0${secs}` : secs
    }`;
  };

  return (
    <>
      <div
        className={`${
          isCollapsed
            ? "rounded-lg w-12 h-12   overflow-hidden mr-1"
            : "relative w-[7rem] md:w-[11rem] lg:w-[13rem]"
        }`}
      >
        <div
          className={`cursor-pointer border rounded-full font-light p-2 flex justify-between items-center
          ${
            status === "online"
              ? "bg-green-200 border-2 text-black"
              : "bg-red-200 border-2 text-black"
          }
          text-sm md:text-base`} // Smaller text on small screens, normal on larger
          onClick={() => setIsOpen(!isOpen)}
        >
          {
            <span>
              {!isCollapsed && (status === "online" ? "Online-" : "Offline-")}
              {status === "online"
                ? formatTime(onlineTime)
                : formatTime(offlineTime)}
            </span>
          }
          {isOpen ? (
            <AiOutlineUp className="w-3 h-3 md:w-4 md:h-4" />
          ) : (
            <AiOutlineDown className="w-3 h-3 md:w-4 md:h-4" />
          )}
        </div>
        {isOpen && (
          <ul
            initial={{ opacity: 0 }}
            className={` ${
              isCollapsed
                ? "absolute z-10 bg-white border border-slate-300 rounded-md w-50% bottom-16 mr-1 "
                : "absolute z-10 w-full bg-white border text-black font-light border-gray-300 rounded-md bottom-full mb-1"
            }  `}
          >
            {options.map((option) => (
              <li
                key={option.value}
                className="cursor-pointer p-2 hover:bg-gray-100 flex items-center"
                onClick={() => handleOptionClick(option.value)}
              >
                <span
                  className={`mr-2 ${
                    status === option.value ? "text-green-500" : "text-gray-500"
                  }`}
                >
                  {status === option.value ? (
                    <AiOutlineCheck className="w-4 h-4" />
                  ) : (
                    <AiOutlineMinus className="w-4 h-4" />
                  )}
                </span>
                {isCollapsed ? (
                  <div className={` rounded-full w-4 h-4 ${option.color}  `}>
                    {console.log(
                      "option label",
                      option.label,
                      "value ",
                      option.value
                    )}
                  </div>
                ) : (
                  option.label
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default AvailableState;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import {
//   AiOutlineCheck,
//   AiOutlineMinus,
//   AiOutlineDown,
//   AiOutlineUp,
// } from "react-icons/ai";
// import "./chatApp.css";

// const AvailableState = ({ onStatusChange, online }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [status, setStatus] = useState({ online }); // Marked Online by default

//   const options = [
//     { value: "online", label: "Online" },
//     { value: "offline", label: "Offline" },
//   ];

//   const handleOptionClick = (option) => {
//     let cState;
//     if (option === "offline") {
//       cState = false;
//     } else {
//       cState = true;
//     }
//     let data = JSON.stringify({
//       isOnline: cState,
//     });

//     let config = {
//       method: "post",
//       maxBodyLength: Infinity,
//       url: "/api/tg",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: data,
//     };

//     axios
//       .request(config)
//       .then((response) => {
//         console.log(JSON.stringify(response.data));
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//     setStatus(option);
//     setIsOpen(false);
//     onStatusChange(option);
//     stateChange(option);
//     // Pass the status change to the parent component
//   };

//   // Function to change status from the console or external messages
//   const stateChange = (newState) => {
//     const state = newState ? "online" : "offline";
//     setStatus(state);
//     onStatusChange(state);
//   };

//   // Function to call the GET API and update the status if it has changed
//   const fetchStatusFromApi = async () => {
//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: "/api/gtg",
//       headers: {},
//     };

//     try {
//       const response = await axios.request(config);
//       const newStatus = response.data.currentStatus ? true : false; // Extracting currentStatus from the response
//       if (status !== newStatus) {
//         stateChange(newStatus);
//       }
//     } catch (error) {
//       console.error("Error fetching status:", error);
//     }
//   };

//   useEffect(() => {
//     window.stateChange = stateChange;

//     const messageListener = (event) => {
//       if (event.data && event.data.type === "statusChange") {
//         stateChange(event.data.status === "online");
//       }
//     };

//     window.addEventListener("message", messageListener);

//     // Set up an interval to check the status every 2 seconds
//     const intervalId = setInterval(fetchStatusFromApi, 2000);

//     return () => {
//       clearInterval(intervalId); // Clean up the interval on component unmount
//       delete window.stateChange; // Clean up when the component is unmounted
//       window.removeEventListener("message", messageListener);
//     };
//   }, [status]); // Adding status as a dependency to track changes

//   return (
//     <div className="relative w-40 md:w-52 lg:min-w-56 ">
//       <div
//         className={`cursor-pointer border rounded-full font-light p-2 flex justify-between items-center
//           ${
//             status === "online"
//               ? "bg-green-50 border-2 text-black"
//               : "bg-red-50 border-2 text-black"
//           }
//           text-sm md:text-base`} // Smaller text on small screens, normal on larger
//         onClick={() => setIsOpen(!isOpen)}
//       >
//         <span id="chatStatus">
//           {status === "online" ? "Online" : "Offline"}
//         </span>
//         {isOpen ? (
//           <AiOutlineUp className="w-3 h-3 md:w-4 md:h-4" />
//         ) : (
//           <AiOutlineDown className="w-3 h-3 md:w-4 md:h-4" />
//         )}
//       </div>
//       {isOpen && (
//         <ul className="absolute z-10 w-full bg-white border text-black font-light border-gray-300 rounded-md bottom-full mb-1">
//           {options.map((option) => (
//             <li
//               key={option.value}
//               className="cursor-pointer p-2 hover:bg-gray-100 flex items-center"
//               onClick={() => handleOptionClick(option.value)}
//             >
//               <span
//                 className={`mr-2 ${
//                   status === option.value ? "text-green-500" : "text-gray-500"
//                 }`}
//               >
//                 {status === option.value ? (
//                   <AiOutlineCheck className="w-4 h-4" />
//                 ) : (
//                   <AiOutlineMinus className="w-4 h-4" />
//                 )}
//               </span>
//               {option.label}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// export default AvailableState;
