import { useState, useRef } from "react";

const useTypingHandler = () => {
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);

  // Function to send typing events
  const sendTypingEvent = async () => {
    try {
      // First API call (OPTIONS)
      await fetch(
        "https://wcapi-us-site-1.imiengage.io/api/conversations/typingcustomevent",
        {
          method: "OPTIONS",
          headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            "access-control-request-headers":
              "authorization,content-type,current,reqtoken",
            "access-control-request-method": "POST",
            "cache-control": "no-cache",
            origin: "https://desktop.wxcc-us1.cisco.com",
            pragma: "no-cache",
            priority: "u=1, i",
            referer: "https://desktop.wxcc-us1.cisco.com/",
            "sec-fetch-dest": "empty",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "cross-site",
            "user-agent":
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36",
          },
        }
      );

      // Second API call (POST with typing event data)
      await fetch(
        "https://wcapi-us-site-1.imiengage.io/api/conversations/typingcustomevent",
        {
          method: "POST",
          headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            authorization:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IjA2Y2VlODlhLTM4YjktNGM0YS04YmE4LTdmNTY2NDU5OTZhNiIsImRvbWFpbiI6IkNvbnNpbGl1bS5pbWkuY2hhdCIsImFnZW50SWQiOiI4MTkwNDJhYy1mNWRjLTRjMDgtODRkOC1mZDQ0MTc2ZTM0YjYiLCJ1aWQiOiI1MDg2NSIsImNpZCI6IjY3MSIsIm5iZiI6MTcyOTU4NDU1OSwiZXhwIjoxNzI5NTk4OTU5LCJpc3MiOiJjaXNjby53ZWJleGNjLmNvbSIsImF1ZCI6IkNvbnNpbGl1bS5pbWkuY2hhdCJ9.LuA4of4FMx3kYnU9lHKu6PPLYCBsO4RZMvgC3XiyX80",
            "cache-control": "no-cache",
            "content-type": "application/json, application/json",
            current: "1729585612757109",
            origin: "https://desktop.wxcc-us1.cisco.com",
            pragma: "no-cache",
            priority: "u=1, i",
            referer: "https://desktop.wxcc-us1.cisco.com/",
            reqtoken:
              "cbe13a064db5bcade6ec755133f8a1cce05f11ec5484047b98e7b00a3fbe2517bfd6dfeae226dd720661f727303f6efa88a41f2d0e720ea680eccb1b868cc828",
            "sec-ch-ua":
              '"Google Chrome";v="129", "Not=A?Brand";v="8", "Chromium";v="129"',
            "sec-ch-ua-mobile": "?0",
            "sec-ch-ua-platform": '"Windows"',
            "sec-fetch-dest": "empty",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "cross-site",
            "user-agent":
              "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36",
          },
          body: JSON.stringify({
            convid: "CSRPXW8NIHKP5E4P",
            eventtype: "typing_on",
            isgroupchat: false,
            internalprompt: true,
            channel: "livechat",
            agentid: "819042ac-f5dc-4c08-84d8-fd44176e34b6",
          }),
        }
      );
    } catch (error) {
      console.error("Error sending typing event: ", error);
    }
  };

  // Function to handle typing and reset inactivity timeout
  const handleTyping = (event) => {
    const message = event.target.value;

    if (!isTyping) {
      setIsTyping(true);
      sendTypingEvent();
    }

    // Reset typing state after 3 seconds of inactivity
    clearTimeout(typingTimeoutRef.current);
    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
    }, 3000);

    return message; // Return the updated message
  };

  return { handleTyping, isTyping }; // Return the handler and typing state
};

export default useTypingHandler;
