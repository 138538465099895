// // transferModal.js
// import React, { useState } from "react";
// import "./transferModal.css"; // Import the modal-specific styles

// const TransferModal = ({ show, onClose, onTransfer }) => {
//   const [selectedOption, setSelectedOption] = useState("agent");
//   const [selectedAgent, setSelectedAgent] = useState("");
//   const agents = ["No agent available"]; // This should be replaced with real data

//   if (!show) {
//     return null;
//   }

//   return (
//     <div className="modal-overlay">
//       <div className="modal-content">
//         <h2>Transfer Request</h2>
//         <p>Select the agent or queue for transferring this contact.</p>
//         <div className="radio-group">
//           <label>
//             <input
//               type="radio"
//               value="agent"
//               checked={selectedOption === "agent"}
//               onChange={() => setSelectedOption("agent")}
//             />
//             Agent
//           </label>
//           <label>
//             <input
//               type="radio"
//               value="queue"
//               checked={selectedOption === "queue"}
//               onChange={() => setSelectedOption("queue")}
//             />
//             Queue
//           </label>
//         </div>
//         {selectedOption === "agent" ? (
//           <div className="dropdown-container">
//             <select
//               value={selectedAgent}
//               onChange={(e) => setSelectedAgent(e.target.value)}
//             >
//               {agents.map((agent, index) => (
//                 <option key={index} value={agent}>
//                   {agent}
//                 </option>
//               ))}
//             </select>
//             <button
//               className="refresh-button"
//               onClick={() => {
//                 /* Fetch updated agent list */
//               }}
//             >
//               ⟳
//             </button>
//           </div>
//         ) : (
//           <div className="queue-selection">
//             <p>No queues available</p>
//           </div>
//         )}
//         <div className="modal-actions">
//           <button onClick={onClose} className="cancel-button">
//             Cancel
//           </button>
//           <button
//             onClick={() => onTransfer(selectedOption, selectedAgent)}
//             className="transfer-button"
//             disabled={!selectedAgent}
//           >
//             Transfer
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TransferModal;
//-------------
// transferModal.js
import React from "react";
import "./transferModal.css"; // Make sure you have this CSS file for styling the modal

const TransferModal = ({ onClose }) => {
  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>Transfer Request</h2>
        <p>Select the agent or queue for transferring this contact.</p>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="transferType"
              value="agent"
              defaultChecked
            />
            Agent
          </label>
          <label>
            <input type="radio" name="transferType" value="queue" />
            Queue
          </label>
        </div>
        <select className="select-dropdown">
          <option value="">No agent available</option>
          {/* Add your agent options here */}
        </select>
        <div className="modal-buttons">
          <button className="btn cancel" onClick={onClose}>
            Cancel
          </button>
          <button className="btn transfer">Transfer</button>
        </div>
      </div>
    </div>
  );
};

export default TransferModal;
