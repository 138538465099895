// Avatar.js
import React from "react";

//Avatar Component for displaying the first letter of the user's name
const Avatar = ({ name }) => {
  const initial = name.charAt(0).toUpperCase();
  return (
    <div className="bg-gray-300 text-gray-800 rounded-full w-8 h-8 flex items-center justify-center mr-2">
      {initial}
    </div>
  );
};

export default Avatar;
