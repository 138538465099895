import React, { useState, useEffect } from "react";
import { AiOutlineDown, AiOutlineUp, AiOutlineClose } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import TransferModal from "./transferModal"; // Import TransferModal component
import "./customerDetails.css";
import { RiFolderTransferFill } from "react-icons/ri";
import { IoCloseCircle } from "react-icons/io5";
import { MdTimer } from "react-icons/md";
import axios from "axios";
import "./loader.css";
const CustomerDetails = ({
  customerName,
  queue,
  email,
  handleSubmit,
  endLoading,
  handleEndConversation,
  selectedWrapUp,
  handleSelectChange,
  showWrapUp,
  disabledButtons,
  threadId,
  endedConversations,
  formatDuration,
  wrapUpThreadId,
  durations,
  onWrapUpSubmit,
}) => {
  const [timer, setTimer] = useState(0); // Timer in seconds
  const [isCollapsed, setIsCollapsed] = useState(true); // Collapsed state
  const [showTransferModal, setShowTransferModal] = useState(false); // State to show/hide the transfer modal

  // Update timer every second
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
    }, 1000);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Function to format the timer to MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  // Function to handle opening the transfer modal
  const handleTransferClick = () => {
    setShowTransferModal(true);
  };

  // Function to handle closing the transfer modal
  const handleCloseModal = () => {
    setShowTransferModal(false);
  };

  const options = {
    "Appointments ": "c143bc1e-f805-431c-bbe9-8566f17535e0",
    "Banking ": "523a57d0-e475-4a23-967f-75cf7457aca4",
    "Customer Interested": "c95e36c3-2fc5-43ff-8876-53db34500123",
  };

  return (
    <div className="bg-white shadow-md rounded-md px-2 py-1 w-full ">
      {/* Header Section */}
      <div className="flex items-center justify-between ">
        <div className="flex flex-col items-start gap-2">
          <div className="flex items-center space-x-2">
            <span className="block w-3 h-3 bg-green-500 rounded-full"></span>
            <span className="font-semibold text-sm">{customerName}</span>
          </div>
          <div className="flex items-center text-xs bg-slate-100 rounded-full p-1 space-x-2">
            <span className="block w-3 h-3  animate-pulse">
              <MdTimer />
            </span>
            <span className="font-mono">
              {formatTime(durations[threadId]?.elapsed || 0)}
            </span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            className="bg-slate-300 flex gap-1 text-xs items-center justify-center text-slate-700  font-light px-2 py-1 rounded-full hover:bg-slate-400 transition-all duration-150"
            onClick={handleTransferClick}
          >
            <RiFolderTransferFill />
            <spam className="font-semibold">Transfer</spam>
          </button>
          <button
            onClick={handleEndConversation}
            disabled={
              disabledButtons[threadId] || endedConversations.includes(threadId)
            }
            className={`${
              disabledButtons[threadId] || endedConversations.includes(threadId)
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-red-500 hover:bg-red-600"
            } text-white px-2 text-xs py-1 flex gap-1 items-center justify-center rounded-full transition-all duration-150`}
          >
            <IoCloseCircle />
            {endLoading ? (
              <span className="endLoader"></span>
            ) : (
              <spam className="font-semibold">End</spam>
            )}
          </button>
          <button
            className="text-slate-900 font-bold text-xs p-1 hover:text-gray-600"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? (
              <AiOutlineUp className="rotate-180 transition-all duration-150" />
            ) : (
              <AiOutlineUp className="rotate-[360deg] transition-all duration-150" />
            )}
          </button>
        </div>
      </div>

      {/* Collapsible Customer Information Section */}
      <AnimatePresence initial={false}>
        {!isCollapsed && (
          <motion.div
            className="py-2 px-2 bg-white rounded-md text-sm border border-slate-500 text-slate-600 overflow-hidden"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <div className="grid grid-cols-1 gap-2">
              <div className="text-sm">
                <span className="font-semibold">Customer Name: </span>
                {customerName}
              </div>
              <div className="text-sm">
                <span className="font-semibold">Contact Origin: </span>
                {email}
              </div>
              <div className="text-sm">
                <span className="font-semibold">Queue: </span>
                {queue}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Wrap-Up Section */}
      {wrapUpThreadId === threadId && endLoading === false && (
        <div className="mt-4 bg-gray-100 p-4 rounded-lg">
          <label
            htmlFor="wrapUpSelect"
            className="block text-gray-600 text-xs font-light mb-1"
          >
            Wrap-Up Options
          </label>
          <select
            id="wrapUpSelect"
            value={selectedWrapUp}
            onChange={handleSelectChange}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="" disabled>
              Select an option
            </option>
            {Object.keys(options).map((value, index) => (
              <option key={index} value={options[value]}>
                {`${value} `}
              </option>
            ))}
          </select>
          <button
            onClick={handleSubmit}
            className="mt-2 w-full py-2 bg-blue-500 text-white text-sm rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Submit
          </button>
        </div>
      )}

      {/* Transfer Modal */}
      <AnimatePresence>
        {showTransferModal && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-white rounded-lg p-6 shadow-lg w-full max-w-md relative"
              initial={{ y: "-50%", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: "-50%", opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {/* Close Button */}
              <button
                className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
                onClick={handleCloseModal}
              >
                <AiOutlineClose size={24} />
              </button>
              <TransferModal onClose={handleCloseModal} />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CustomerDetails;
